@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: sf-pro-bold;
  src: url("/src/assets/fonts/SFPRODISPLAYBOLD.OTF") format("opentype");
}

@font-face {
  font-family: sf-pro-med;
  src: url("/src/assets/fonts/SFPRODISPLAYMEDIUM.OTF") format("opentype");
}

@font-face {
  font-family: sf-pro-reg;
  src: url("/src/assets/fonts/SFPRODISPLAYREGULAR.OTF") format("opentype");
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid white;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #0e0e0f inset;
  box-shadow: 0 0 0px 1000px #0e0e0f inset;
  transition: background-color 5000s ease-in-out 0s;
  color: white;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

.active {
  background-color: #181818;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@layer components {
  .smallText {
    @apply text-white text-base font-sf-med;
  }
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 400px;
  height: 6px;
  background-color: #505050;
  border-radius: 7px;
  outline: none;
  @apply drop-shadow-lg;
}

.slider2 {
  width: 100px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  display: none;
  @apply drop-shadow-lg;
}

.slider::-moz-range-thumb {
  height: 19px;
  width: 19px;
  background-color: white;
  border-radius: 100%;
  border: #505050 1px solid;
  cursor: pointer;
  @apply drop-shadow-lg;
}